<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="menuItems" />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'

import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

import { rolesAccess } from '../../../../../utils/roles-access'
import store from '../../../../../store'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    const user = computed({
      get: () => store.state.auth.user,
    })

    const menuItems = computed({
      get: () => {
        return navMenuItems.filter(
          (i) =>
            rolesAccess[i.route].includes('all') ||
            rolesAccess[i.route].includes(user.value ? user.value.role : ''),
        )
      },
    })

    return {
      menuItems,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/horizontal-menu.scss';
</style>
